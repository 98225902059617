import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import {
  STASH, PIP, NOT_APPLICABLE, ATC, PRODUCT_POD
} from '../constants';

export const fbt = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('fbt.click', ({ output = {} }) => {
    const {
      itemId = '',
      displayPosition = '',
      apiName = output?.bundle ? 'product bundle' : 'fbt',
      modelName = NOT_APPLICABLE,
      version = NOT_APPLICABLE
    } = output;

    // @todo this is only bundle path
    const ddoEvent = {
      category: {
        primaryCategory: STASH
      },

      eventInfo: {
        eventName: 'recommendation pip load'
      },

      eventMode: STASH,

      stash: {
        recommendation: {
          recommendationType: apiName,
          pageType: PIP,
          anchorProductSku: itemId,
          displayPosition,
          strategy: modelName,
          recommendationVersion: version,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        },
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('fbt.add-to-cart', ({ output = {} }) => {
    const {
      items = [],
      bundle = false,
      modelName = NOT_APPLICABLE,
      version = NOT_APPLICABLE,
      apiName = ''
    } = output;

    const { pageType = '', pageItemId = '' } = getState() || {};

    const refinedItems = items.map((item, itemIndex) => {
      const { itemId, productId = '', strategy } = item;
      const sku = itemId ? itemId.toString() : productId.toString();
      const itemStrategy = strategy !== undefined ? strategy : NOT_APPLICABLE;
      const scheme = apiName || 'fbt';
      if (items?.length > 1 && itemId === pageItemId) {
        return {
          productInfo: {
            sku
          }
        };
      }
      return {
        productInfo: {
          sku
        },
        displayPosition: bundle ? NOT_APPLICABLE : itemIndex + 1,
        recommendation: {
          recommendationType: bundle ? 'product bundle' : scheme,
          pageType: PIP,
          anchorProductSku: pageItemId,
          strategy: bundle ? modelName : itemStrategy,
          recommendationVersion: bundle ? version : NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE,
        }
      };
    });

    const ddoEvent = {
      eventInfo: {
        eventName: 'recommendation cart addition'
      },
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      stash: {
        component: PRODUCT_POD,
        item: refinedItems,
        pageType,
        section: 'dyn prod rec',
        sharedSection: NOT_APPLICABLE,
        target: ATC
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};